<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'id'"
        :sortOrder=-1
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        :showAddButton="false"
        :showDropdown="true"
        :extraOptions=extraOptions
        :hideFooter="true"
        :checkIfCanShowDelete="checkIfCanShowDelete"
    >
        <template #columns>
            <Column field="created_at" :header="$t('overview.date')" style='width:15%' :sortable="false">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'dateTimeNoSeconds')}}
                </template>
            </Column>
            <Column field="direction" style='width:8%' :header="$t('overview.direction')" :sortable="false"></Column>
            <Column field="vin" :header="$t('overview.vin')" :sortable="false"></Column>
            <Column field="external_api_log.job_unit_id" header="Matched?" style='width:80px' :sortable="false">
                <template #body="slotProps">
                    <i v-if='slotProps.data.job_unit_id || slotProps.data.job_booking_voyage_job_unit_id' class='fas fa-check text-success' />
                    <i v-else class='fas fa-close text-danger' />
                </template>
            </Column>
            <Column field="type" style='width:12%' :header="$t('overview.type')" :sortable="false"></Column>
            <Column field="sub_type" style='width:12%' :header="$t('overview.subtype')" :sortable="false"></Column>
            <Column field="sequence_number" style='width:8%' :header="$t('overview.sequence')" :sortable="false"></Column>
        </template>

        <template #form>
            <externalLogForm ref="ItemForm" :itemData="item" :showType="showType"/>
        </template>

        
    </Overview>
    
</template>

<script>
import Overview from '@/components/Overview';
import Column from '@/components/column';
import externalLogForm from '@/components/log/ExternalLogForm';
import externalLogService from '@/services/ExternalEdiLogService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        externalLogForm,
    },
    data() {
        return {
            showType: 'unit',
            extraOptions: {},
            item: {},
            itemService: externalLogService,
            itemFormRef: null,
            selectFields: [
                'id',
                'direction',
                'vin',
                'type',
                'sub_type',
                'sequence_number',
                'external_api_log_id',
                'created_at',
                'data',
                "job_unit_id",
                'job_booking_voyage_job_unit_id'
            ]
        }
    },
    methods:{
        checkIfCanShowDelete(){
            return false
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
        
        this.extraOptions = [{
            icon: 'link',
            name: "Show unit details",
            function: (slotProps) => {
                    this.showType = 'unit'
                    let form = this.$refs.ItemForm;
                    form.itemLoading = true;
                    document.getElementById("deeplinkModalButton").click();
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            externalLogService.show(slotProps.data.id).then(response => {
                                form.itemLoading = false;
                                form.log = this.itemFormRef.getData();
                                
                                this.itemFormRef.setData(response.data);
                            })
                        })
                    })
                }
            },{
                icon: 'link',
                name: "Show full EDI message",
                function: (slotProps) => {
                    this.showType = 'full'
                    let form = this.$refs.ItemForm;
                    form.itemLoading = true;
                    document.getElementById("deeplinkModalButton").click();
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.itemFormRef.reset();
                            externalLogService.show(slotProps.data.id).then(response => {
                                form.itemLoading = false;
                                form.log = this.itemFormRef.getData();
                                
                                this.itemFormRef.setData(response.data);
                            })
                        })
                    })
                }
            },{
                icon: 'link',
                name: "Go to EDI log",
                function: (slotProps) => {
                    this.showType = 'basic'
                    let form = this.$refs.ItemForm;
                    form.itemLoading = true;
                    document.getElementById("deeplinkModalButton").click();
                    this.$nextTick(() => {
                        this.itemFormRef.active(true);
                        this.$nextTick(() => {
                            this.itemFormRef.reset();
                            externalLogService.show(slotProps.data.id).then(response => {
                                form.itemLoading = false;
                                form.log = this.itemFormRef.getData();
                                
                                this.itemFormRef.setData(response.data);
                            })
                        })
                    })
                }
            }];
    }
}
</script>